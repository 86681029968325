@import "https://use.fontawesome.com/ede1f9233a.css";
@import url(https://fonts.googleapis.com/css?family=Sanchez);

/*Styling subsidie aanvraag*/
form[name='frmUploadDocumenten']{
  padding: 0 1.5625rem;
  font-family: "Source Sans Pro",sans-serif;
  .formbuilder_element{
    overflow: hidden;
    padding:0px 0px 20px 15px;
    border-bottom: 1px solid;
    margin: 0px 0 20px;
    label{
      display: block;
      margin: 0 0 10px 0;
    }
    span{
      font-weight: bold;
    }
  }
  .formbuilder_form_submit_button{
    padding: 0 0 0 1.5625rem;
    text-align: right;
    input[type=button]{
      background-color: #231f20;
      border-color: #231f20;
      color: #fff;
      box-shadow: none;
      cursor: default;
      font-size: 1rem;
      padding: 1rem;
    }
  }
}
div.jfilestyle input{
  height: auto !important;
  display: inherit !important;
}
.formulier_container{
  color: #2f2f2f;
  font-family: "Source Sans Pro",sans-serif;
  font-size: 1rem;
  margin:0 0 0 22px;
}


